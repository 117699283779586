import { RouterModule, Routes } from '@angular/router';
import { WrapperComponent } from '@shared/layouts/wrapper/wrapper.component';

export const routes: Routes = [
  {
    path: '',
    component: WrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'club',
        loadChildren: () =>
          import('./club/club.module').then(m => m.ClubModule),
      },
      {
        path: 'club/login',
        loadChildren: () =>
          import('./club/club.module').then(m => m.ClubModule),
      },
      {
        path: 'timer',
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'reset/password',
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'confirm-login',
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'confirm-hotline',
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'confirm-contact',
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'tips',
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'unsubscribe',
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'hotline',
        loadChildren: () =>
          import('./hotline/hotline.module').then(m => m.HotlineModule),
      },
      {
        path: 'unsubscribe-confirmation',
        loadChildren: () =>
          import('./interstitial/split/split.module').then(m => m.SplitModule),
      },
      {
        path: 'thankshotline',
        loadChildren: () =>
          import('./interstitial/split/split.module').then(m => m.SplitModule),
      },
      {
        path: 'thanksclassvip',
        loadChildren: () =>
          import('./interstitial/split/split.module').then(m => m.SplitModule),
      },
      {
        path: 'classvip',
        loadChildren: () =>
          import('./interstitial/split/split.module').then(m => m.SplitModule),
      },
      {
        path: 'classvippromo1upsell',
        loadChildren: () =>
          import('./interstitial/split/split.module').then(m => m.SplitModule),
      },
      {
        path: 'classvip-promo-1',
        loadChildren: () =>
          import('./interstitial/split/split.module').then(m => m.SplitModule),
      },
      {
        path: 'gpa',
        loadChildren: () =>
          import('./interstitial/split/split.module').then(m => m.SplitModule),
      },
      {
        path: 'blog',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./blog/blog.module').then(m => m.BlogModule),
          },
          {
            path: 'pages/:number',
            loadChildren: () =>
              import('./blog/blog.module').then(m => m.BlogModule),
          },
          {
            path: ':name',
            loadChildren: () =>
              import('./blog/blog.module').then(m => m.BlogModule),
          },
          {
            path: ':name/pages/:number',
            loadChildren: () =>
              import('./blog/blog.module').then(m => m.BlogModule),
          },
        ],
      },
      {
        path: 'plus',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./plus/plus.module').then(m => m.PlusModule),
          },
          {
            path: ':id',
            loadChildren: () =>
              import('./plus/plus.module').then(m => m.PlusModule),
          },
        ],
      },
      {
        path: 'action',
        loadChildren: () =>
          import('./plus/plus.module').then(m => m.PlusModule),
      },
    ],
  },
  {
    path: 'article',
    component: WrapperComponent,
    children: [
      {
        path: ':id/:slug',
        loadChildren: () =>
          import('./blog/article/article.module').then(m => m.ArticleModule),
      },
    ],
  },
  {
    path: 'video',
    component: WrapperComponent,
    children: [
      {
        path: ':id/:slug',
        loadChildren: () =>
          import('./video/video.module').then(m => m.VideoModule),
      },
    ],
  },
  {
    path: 'pages',
    component: WrapperComponent,
    children: [
      {
        path: ':name',
        loadChildren: () =>
          import('./pages/pages.module').then(m => m.PagesModule),
      },
    ],
  },
  {
    path: '',
    data: { skipRouteLocalization: true },
    children: [
      {
        path: '404',
        loadChildren: () =>
          import('./not-found/not-found.module').then(m => m.NotFoundModule),
      },
      {
        path: 'unsubscribe-confirmation',
        component: WrapperComponent,
        loadChildren: () =>
          import('./interstitial/split/split.module').then(m => m.SplitModule),
      }
    ],
  },
];
// must use {initialNavigation: 'enabled'}) - for one load page, without reload
export const AppRoutes = RouterModule.forRoot(routes, {
  initialNavigation: 'disabled',
  scrollPositionRestoration: 'top',
});
